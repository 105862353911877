<!--数据对照-->
<template>
  <div class="container compare-box">
    <div class="row">
      <div class="col-md-12">
        <h1 class="text-capitalize">数据分析</h1>
      </div>
    </div>
    <el-row>
      <b-card class="col-ms-12 filter" bg-variant="light">
        <b-form-group
            label="固废来源"
            label-cols-sm="5"
            label-align-sm="right"
        >
          <el-select
              v-model="searchModel.sources"
              multiple
              collapse-tags
              placeholder="请选择"
              @change="queryWasteBigSelect"
          >
            <el-option
                v-for="item in options.wasteSource"
                :key="item.value"
                :label="item.label"
                :value="item.value">
            </el-option>
          </el-select>
        </b-form-group>
        <b-form-group
            label="固废大类"
            label-cols-sm="5"
            label-align-sm="right"
        >
          <el-select
              v-model="searchModel.wasteBigTypes"
              multiple
              collapse-tags
              placeholder="请选择"
              @change="queryWasteSmallSelect"
          >
            <el-option
                v-for="item in options.wasteBigType"
                :key="item.value"
                :label="item.label"
                :value="item.value">
            </el-option>
          </el-select>
        </b-form-group>
        <b-form-group
            label="固废小类"
            label-cols-sm="5"
            label-align-sm="right"
        >
          <el-select
              v-model="searchModel.wasteSmallTypes"
              multiple
              collapse-tags
              placeholder="请选择"
          >
            <el-option
                v-for="item in options.wasteSmallType"
                :key="item.value"
                :label="item.label"
                :value="item.value">
            </el-option>
          </el-select>
        </b-form-group>
        <b-form-group
            label="资源化产品大类"
            label-cols-sm="5"
            label-align-sm="right"
        >
          <el-select
              v-model="searchModel.productBigTypes"
              multiple
              collapse-tags
              placeholder="请选择"
              @change="queryProductSmallSelect"
          >
            <el-option
                v-for="item in options.productBigType"
                :key="item.value"
                :label="item.label"
                :value="item.value">
            </el-option>
          </el-select>
        </b-form-group>
        <b-form-group
            label="资源化产品小类"
            label-cols-sm="5"
            label-align-sm="right"
        >
          <el-select
              v-model="searchModel.productSmallTypes"
              multiple
              collapse-tags
              placeholder="请选择"
              @change="queryProductTechSelect"
          >
            <el-option
                v-for="item in options.productSmallType"
                :key="item.value"
                :label="item.label"
                :value="item.value">
            </el-option>
          </el-select>
        </b-form-group>
        <b-form-group
            label="资源化技术"
            label-cols-sm="5"
            label-align-sm="right"
        >
          <el-select
              v-model="searchModel.productTechs"
              multiple
              collapse-tags
              placeholder="请选择">
            <el-option
                v-for="item in options.productTech"
                :key="item.value"
                :label="item.label"
                :value="item.value">
            </el-option>
          </el-select>
        </b-form-group>
        <div class="search el-col-md-24">
          <el-button type="primary" @click="selectData">查 询</el-button>
        </div>
      </b-card>

      <b-card
          class="col-ms-12 search-data"
          header="查询结果"
      >
        <div ref="charts" class="echarts-content"></div>
      </b-card>
    </el-row>
  </div>
</template>

<script>
import {
  getWasteSourceSelect,
  getWasteBigTypeSelect,
  getWasteSmallTypeSelect,
  getProductBigSelect,
  getProductSmallSelect,
  getProductTechSelect
} from "@/api/common"
import {compare} from "@/api/product"

export default {
  name: "compare",
  data() {
    return {
      charts: '',
      searchModel: {
        sources: [],
        wasteBigTypes: [],
        wasteSmallTypes: [],
        productBigTypes: [],
        productSmallTypes: [],
        productTechs: []
      },
      options: {
        wasteSource: [],
        wasteBigType: [],
        wasteSmallType: [],
        productBigType: [],
        productSmallType: [],
        productTech: [],
        map: {}
      },
      table: {
        tableData: [],
        total: 0,
        currentPage: 1,
        pageCount: 0,
        pageSizes: 20
      },
      mapOptions: {
        tooltip: {
          trigger: 'axis',
          axisPointer: {            // 坐标轴指示器，坐标轴触发有效
            type: 'shadow'        // 默认为直线，可选为：'line' | 'shadow'
          }
        },
        toolbox: {
          feature: {
            dataView: {show: true, readOnly: false},
            magicType: {show: true, type: ['line', 'bar']},
            restore: {show: true},
            saveAsImage: {show: true}
          }
        },
        legend: {
          data: ['资源影响','环境影响','经济影响','社会影响']
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        axisLabel: {
          interval: 0,
          formatter:function(value)
          {
            var ret = "";//拼接加\n返回的类目项
            var maxLength = 8;//每项显示文字个数
            var valLength = value.length;//X轴类目项的文字个数
            var rowN = Math.ceil(valLength / maxLength); //类目项需要换行的行数
            if (rowN > 1)//如果类目项的文字大于5,
            {
              for (var i = 0; i < rowN; i++) {
                var temp = "";//每次截取的字符串
                var start = i * maxLength;//开始截取的位置
                var end = start + maxLength;//结束截取的位置
                //这里也可以加一个是否是最后一行的判断，但是不加也没有影响，那就不加吧
                temp = value.substring(start, end) + "\n";
                ret += temp; //凭借最终的字符串
              }
              return ret;
            }
            else {
              return value;
            }
          }
        },
        xAxis: [
          {
            type: 'category',
            data: []
          }
        ],
        yAxis: [
          {
            type: 'value'
          }
        ],
        series: [
        ]
      },
    }
  },
  mounted() {
    this.initChart()
    this.init()
  },
  methods: {
    initChart() {
      // debugger
      this.charts = this.$echarts.init(this.$refs.charts);
      this.options.map = this.mapOptions
      this.charts.setOption(this.options.map);
    },
    init() {
      this.queryWasteSourceSelect()
      this.queryProductBigSelect()
      this.queryProductTechSelect()
      this.selectData()
    },
    queryWasteSourceSelect() {
      getWasteSourceSelect().then(data => {
        this.options.wasteSource = data
      })
    },
    queryWasteBigSelect() {
      // debugger
      if (this.searchModel.sources.length === 0) return
      getWasteBigTypeSelect(this.searchModel.sources).then(data => {
        this.options.wasteBigType = data
      })
    },
    queryWasteSmallSelect() {
      if (this.searchModel.wasteBigTypes.length === 0) return
      getWasteSmallTypeSelect(this.searchModel.wasteBigTypes).then(data => {
        this.options.wasteSmallType = data
      })
    },
    queryProductBigSelect() {
      getProductBigSelect().then(data => {
        this.options.productBigType = data
      })
    },
    queryProductSmallSelect() {
      if (this.searchModel.productBigTypes.length === 0) return
      getProductSmallSelect(this.searchModel.wasteSmallTypes, this.searchModel.productBigTypes).then(data => {
        this.options.productSmallType = data
      })
    },
    queryProductTechSelect(productSmallType) {
      getProductTechSelect(productSmallType).then(data => {
        this.options.productTech = data
      })
    },
    queryTableData(searchParams) {
      if (!searchParams) {
        searchParams = this.searchModel
      }
      if (this.searchModel.indicator === '') {
        this.$notify({
          title: '提示',
          message: '指标值必选'
        });
        return
      }
      let self = this
      this.options.map = {}
      compare(this.searchModel).then(data => {
        // debugger
        let options = this.mapOptions
        options.xAxis[0].data = data.xlabels
        options.legend.data = data.legends
        for (let i = 0; i < data.series.length; i++) {
          options.series[i] = {
            name: data.series[i].name,
            data: data.series[i].data,
            type: 'bar',
            stack: '影响指标',
            emphasis: {
              focus: 'series'
            },
            smooth: true
          }
        }
        self.charts.setOption(options);
      })
    },
    selectData() {
      this.queryTableData()
    },
    handleCurrentChange(val) {
      const page = {
        pageNo: val,
        pageSize: this.table.pageSizes
      }
      this.queryTableData(this.searchModel, page)
    }
  }
}
</script>

<style lang="less" scoped>
@import "../../assets/less/compare.less";

.echarts-content {
  height: 450px;
  width: 100%;
}
</style>